import React, {useCallback, useEffect, useState} from "react";
import {Label} from "flowbite-react";
import {useErrorReporter} from "./ErrorViewer";
import {useTranslation} from "react-i18next";
import {useAuth} from "../auth/AuthHook";

interface UserNameTagProps {
    userId?: string
    label?: string
    extraClassNames?: string
    extraLabelClassNames?: string
}
export function UserNameTag(props: UserNameTagProps) {
    const [name, setName] = useState("")
    const {getHeaders} = useAuth();
    const reporter = useErrorReporter()
    const {t}= useTranslation()
    const fetchUserCreated = useCallback(async (userId?: string) => {
        if (userId === undefined) {
            return
        }
        const response = await fetch(`/api/users/${userId}`, {
            method: "GET",
            headers: await getHeaders()
        });
        if (response.status == 404) {
            setName(`id (no entry): ${userId}`)
            return
        }
        if (!response.ok || response.status > 299) {
            const errorMsg = await response.text()
            reporter(errorMsg)
            return
        }
        const respJson = await response.json()

        setName(respJson["name"])
    }, [getHeaders, reporter])

     
    useEffect(() => {
        fetchUserCreated(props.userId)
    }, [props.userId, fetchUserCreated]);
    
    return (
        <div>
            <div className={`mb-2 block ${props.label === undefined ? "hidden" : ""}`}>
                <Label className={props?.extraLabelClassNames} value={props.label}/>
            </div>
            <div className={`${props.extraClassNames}`}>
                   {name == "" ? t("Someone (from the organization)") : name}
            </div>
        </div>

    )

}