import React, {KeyboardEvent, useCallback, useState} from "react";
import axios, {AxiosProgressEvent} from "axios";
import {useErrorReporter} from "./ErrorViewer";
import {useAuth} from "../auth/AuthHook";

interface CaseInputProps {
    disabled: boolean
    inputHandler: (input: string, file_id?: string) => Promise<boolean>
    caseId: string | null

}

interface FileInfo {
    file_id: string
    file_name: string
}

interface FileUploadResponse extends FileInfo {
}


export function CaseInput({disabled, inputHandler, caseId}: CaseInputProps) {
    const [text, setText] = useState("")
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null)
    const [progressPercent, setProgressPercent] = useState(0)
    const [isUploading, setIsUploading] = useState(false)
    const {getHeaders} = useAuth()
    const reportError = useErrorReporter()

    const handleInputSubmit = useCallback(async () => {
        const ts = `${text}`
        setText("")
        setFileInfo(null)
        const success = await inputHandler(ts, fileInfo?.file_id)
        if (!success) {
            setText(ts)
            setFileInfo(fileInfo)
        }
    }, [fileInfo, inputHandler, setFileInfo, setText, text])

    const submitOnCommandEnter = useCallback(async (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.getModifierState("Meta") && event.key === "Enter") {
            if (!event.repeat) {
                handleInputSubmit()
            }
            event.preventDefault(); // Prevents the addition of a new line in the text field
        }
    }, [handleInputSubmit])

    const handleUploadProgress = useCallback((progressEvent: AxiosProgressEvent) => {
        if (progressEvent.loaded != undefined && progressEvent.total != undefined) {
            setProgressPercent(Math.round((100 * progressEvent.loaded / progressEvent.total)))
        }
    }, [setProgressPercent])

    const handleFileListChange  = useCallback(async (files: FileList | null) => {
        if (files == null || files.length === 0) {
            return
        }
        const file = files[0]
        if (caseId == null) {
            return
        }
        if (file.size > 400 * 1024 ) {
            reportError("File too large. Maximum size is 400kB")
            return
        }
        if (file.type !== "text/plain") {
            reportError("Only text files are allowed")
            return
        }
        setIsUploading(true)
        try {
            const form = new FormData();
            form.append('file', file)
            const response = await axios.post(`/api/cases/${caseId}/attachments`, form, {
                headers: {
                    ...await getHeaders(),
                    'Content-Type': 'multipart/form-data',
                    'X-File-Name': file.name,
                    'X-File-Size': file.size.toString(),
                    'Encoding': 'multipart/form-data'
                },
                onUploadProgress: handleUploadProgress
            }) as {"data": FileUploadResponse};
            setFileInfo(response.data)
        } catch (e: unknown) {
            if(e instanceof Error) {
                reportError(e.message)
            }
        } finally {
            setIsUploading(false)
        }

    }, [reportError, caseId, handleUploadProgress, getHeaders, setFileInfo, setIsUploading])

    const isDisabled = caseId == null || disabled;

    const file_name = fileInfo == null ? "" : fileInfo.file_name
    return (
        <div className="flex flex-col items-center pb-4">
                <div className="w-full flex px-4 py-4 justify-center items-center relative bg-gray-really max-w-[850px] ">
                    <textarea
                        id="chat-input-textarea-id"
                        placeholder="Type a new question (e.g. Why AKS did not scale up?)"
                        className="rounded-lg py-4 pl-[4.5rem] pr-16 w-full h-auto max-h-48 border border-gray-200 focus:border-blue-enov bg-gray-really text-white field-sizing-normal overflow-hidden"
                        disabled={isDisabled}
                        rows={1}
                        maxLength={8000}
                        onChange={
                            e => {
                                setText(e.target.value)
                                e.target.style.height = "auto"
                                e.target.style.height = `${Math.round(Math.min(e.target.scrollHeight, 220))}px`
                            }
                        }
                        onKeyDown={submitOnCommandEnter}
                        value={text}/>
                    <button type="button" className="absolute bottom-8 right-8 text-blue-enov" disabled={isDisabled} onClick={handleInputSubmit}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20" fill="currentColor"
                             className="w-6 h-6">
                            <path fillRule="evenodd"
                                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                                  clipRule="evenodd"/>
                        </svg>
                    </button>
                    <div className="absolute bottom-8 left-8 flex flex-col">
                        <label htmlFor="case-input-attachment-input-id" className={`${fileInfo == null ? "" : "text-blue-enov"}`} data-testid="case-input-attachement-label">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                 stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"/>
                            </svg>
                            <input id="case-input-attachment-input-id" type="file" className="hidden" disabled={isDisabled}
                                   onChange={(e) => handleFileListChange(e.target.files)} data-testid="case-input-attachment">
                            </input>
                        </label>
                        <progress value={progressPercent} max="100"
                                  className={`w-20 h-1 ${isUploading ? "" : "hidden"}`} data-testid="progress"></progress>
                        <div className={`text-blue-enov text-xs text-opacity-20 ${fileInfo == null ? "hidden" : ""}`} data-testid="filesdiv">
                            {file_name}
                        </div>
                    </div>
                </div>
        </div>
    )
}
