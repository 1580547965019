import React, {createContext, ReactNode, useCallback, useContext, useState} from 'react';

interface ErrorSetterContext {
    error: string | null
    reportError: (newError: string) => void
    isDefault: boolean
}

const ErrorContext = createContext<ErrorSetterContext>({
    error: null,
    reportError: (newError) => {},
    isDefault: true
});

export function ErrorViewer() {
    const {error, isDefault} = useContext(ErrorContext)
    if (isDefault) {
        return (
            <div
                className={`bg-red-300 text-red-900 px-4 border-2 rounded-lg flex-wrap transition duration-300`}>
                {"Wrap your app into an ErrorStateDomain"}
            </div>
        )
    }
    return (
        <div
            className={`bg-red-300 text-red-900 px-4 border-2 rounded-lg flex-wrap transition duration-500 ${error == null || error == "" ? "hidden" : ""}`}>
            {error}
        </div>
    )
}


interface ErrorStateDomainProps {
    children: ReactNode
}

interface PersistedErrorState {
    error: string
    timestamp: number
}

export function ErrorStateDomain({children}: ErrorStateDomainProps) {
    const [error, setError] = useState<string | null>(null)
    const handleNewError = useCallback((error: string | null) => {
        const errorToClear = `${error}`
        setError(error ? error : "")
        const errors = localStorage.getItem("reported_errors")
        const errorList: PersistedErrorState[] = errors != null ? JSON.parse(errors) : []
        localStorage.setItem("reported_errors", JSON.stringify([...errorList, {
            error: errorToClear,
            timestamp: Date.now()
        }]))
        setTimeout(() => { setError((current) => (current == errorToClear) ? "" : current)}, 15000)
    }, [setError])
    return (
        <ErrorContext.Provider
            value={
                {
                    error: error,
                    reportError: handleNewError,
                    isDefault: false
                }
            }>
            {children}
        </ErrorContext.Provider>
    )
}

export const useErrorReporter = () => useContext(ErrorContext).reportError