import {ReactNode} from "react";

interface TitleBoxProps {
    children: ReactNode
    extraClassNames?: string
    lineHidden: boolean
}

export function TitleBox(props: TitleBoxProps) {
    return (
        <div className="flex flex-col items-center lg:min-h-[10%]">
            <div className={`flex flex-col justify-evenly grow w-full text-center py-8 ${props.extraClassNames === undefined ? "" : props.extraClassNames}`}>
                {props.children}
            </div>
        </div>
    )
}