import {Button, ButtonProps} from "flowbite-react";

export function ActionButton(props: ButtonProps) {
    return (
        <Button
            {...props}
            className={`bg-gray-800 dark:bg-gray-800 hover:bg-gray-600 dark:enabled:hover:bg-gray-600 w-full border-t-4 disabled:bg-gray-800 focus:ring-1 focus:ring-gray-really dark:focus:ring-gray-really ${props.className ?? ""}`}
        >
        </Button>
    )
}
