import React, {SetStateAction, useCallback, useState} from "react";
import {useErrorReporter} from "./ErrorViewer";
import {CaseFeedback} from "./model";
import {Tooltip} from "flowbite-react";
import {useAuth} from "../auth/AuthHook";

interface FeedbackProps {
    caseId: string
    feedback?: CaseFeedback
}

export function Feedback({caseId, feedback}: FeedbackProps) {
    const [pendingFeedback, setPendingFeedback] = useState<CaseFeedback | undefined>(undefined)
    const {getHeaders} = useAuth()
    const [feedbackState, setFeedbackState] = useState<boolean | undefined>(feedback?.satisfied)
    const [feedbackText, setFeedbackText] = useState(feedback?.feedback_text ?? "")
    const [feedbackFocused, setFeedbackFocused] = useState(false)
    const reportError = useErrorReporter()


    const sendFeedback = useCallback(async (obj: CaseFeedback) => {
        setPendingFeedback(obj)
        try {
            const response = await fetch(`/api/cases/${caseId}/feedback`, {
                method: 'POST',
                headers: {
                    ...await getHeaders(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(obj)
            })
            if (response.ok) {
                await response.json()
            } else {
                reportError(`Failed to send feedback: ${response.status}, error: ${await response.text()}`)
            }
        } finally {

            setPendingFeedback(undefined)
        }
    }, [caseId, getHeaders, reportError])


    const handleThumbsPress = useCallback(async (feedback: boolean) => {
        await sendFeedback({satisfied: feedback})
        setFeedbackState(feedback)
    }, [sendFeedback])

    const thumbsUp = useCallback((isPending: boolean) => {
        return (
            <svg className={`w-8 h-8 ${isPending ? "animate-pulse" : ""}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                 height="24"
                 fill="none"
                 viewBox="0 0 24 24"
                 aria-label="thumbs up icon">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M7 11c.889-.086 1.416-.543 2.156-1.057a22.323 22.323 0 0 0 3.958-5.084 1.6 1.6 0 0 1 .582-.628 1.549 1.549 0 0 1 1.466-.087c.205.095.388.233.537.406a1.64 1.64 0 0 1 .384 1.279l-1.388 4.114M7 11H4v6.5A1.5 1.5 0 0 0 5.5 19v0A1.5 1.5 0 0 0 7 17.5V11Zm6.5-1h4.915c.286 0 .372.014.626.15.254.135.472.332.637.572a1.874 1.874 0 0 1 .215 1.673l-2.098 6.4C17.538 19.52 17.368 20 16.12 20c-2.303 0-4.79-.943-6.67-1.475"/>
            </svg>);
    }, [])

    const thumbsUpSelected = useCallback(() => {
        return (
            <svg className="w-8 h-8 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                 width="24" height="24" fill="currentColor" viewBox="0 0 24 24" aria-label="selected thumbs up icon">
                <path fillRule="evenodd"
                      d="M15.03 9.684h3.965c.322 0 .64.08.925.232.286.153.532.374.717.645a2.109 2.109 0 0 1 .242 1.883l-2.36 7.201c-.288.814-.48 1.355-1.884 1.355-2.072 0-4.276-.677-6.157-1.256-.472-.145-.924-.284-1.348-.404h-.115V9.478a25.485 25.485 0 0 0 4.238-5.514 1.8 1.8 0 0 1 .901-.83 1.74 1.74 0 0 1 1.21-.048c.396.13.736.397.96.757.225.36.32.788.269 1.211l-1.562 4.63ZM4.177 10H7v8a2 2 0 1 1-4 0v-6.823C3 10.527 3.527 10 4.176 10Z"
                      clipRule="evenodd"/>
            </svg>

        )
    }, [])

    const thumbsDown = useCallback((isPending: boolean) => {
        return (
            <svg className={`w-8 h-8 ${isPending ? "animate-pulse" : ""}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24"
                 height="24" fill="none" viewBox="0 0 24 24" aria-label="thumbs down icon">
                <title>thumbs-down</title>
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M17 13c-.889.086-1.416.543-2.156 1.057a22.322 22.322 0 0 0-3.958 5.084 1.6 1.6 0 0 1-.582.628 1.549 1.549 0 0 1-1.466.087 1.587 1.587 0 0 1-.537-.406 1.666 1.666 0 0 1-.384-1.279l1.389-4.114M17 13h3V6.5A1.5 1.5 0 0 0 18.5 5v0A1.5 1.5 0 0 0 17 6.5V13Zm-6.5 1H5.585c-.286 0-.372-.014-.626-.15a1.797 1.797 0 0 1-.637-.572 1.873 1.873 0 0 1-.215-1.673l2.098-6.4C6.462 4.48 6.632 4 7.88 4c2.302 0 4.79.943 6.67 1.475"/>
            </svg>);
    }, [])

    const sendIcon = useCallback(() => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="2 2 20 20" fill="currentColor"
                 className="w-4 h-4">
                <path fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm14.024-.983a1.125 1.125 0 0 1 0 1.966l-5.603 3.113A1.125 1.125 0 0 1 9 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113Z"
                      clipRule="evenodd"/>
            </svg>
        )
    }, [])

    const thumbsDownSelected = useCallback(() => {
        return (
            <svg className="w-8 h-8 dark:text-white" aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24"
                aria-label="selected thumbs down icon">
                <path fillRule="evenodd"
                      d="M8.97 14.316H5.004c-.322 0-.64-.08-.925-.232a2.022 2.022 0 0 1-.717-.645 2.108 2.108 0 0 1-.242-1.883l2.36-7.201C5.769 3.54 5.96 3 7.365 3c2.072 0 4.276.678 6.156 1.256.473.145.925.284 1.35.404h.114v9.862a25.485 25.485 0 0 0-4.238 5.514c-.197.376-.516.67-.901.83a1.74 1.74 0 0 1-1.21.048 1.79 1.79 0 0 1-.96-.757 1.867 1.867 0 0 1-.269-1.211l1.562-4.63ZM19.822 14H17V6a2 2 0 1 1 4 0v6.823c0 .65-.527 1.177-1.177 1.177Z"
                      clipRule="evenodd"/>
            </svg>
        );
    }, [])

    const sendTextFeedback = useCallback(async () => {
        await sendFeedback({feedback_text: feedbackText})
    }, [sendFeedback, feedbackText])
    const borderColorClause = feedbackFocused ? "border-blue-enov" : "border-gray-700"

    const pendingTextFeedback = pendingFeedback?.feedback_text !== undefined;
    return (
        <div className="flex flex-col justify-end text-gray-200 px-6 items-center gap-4 py-6">
            <div className="text-center text-gray-300">
                Do you consider this case helpful in solving future issues?
            </div>

            <div className="flex flex-row justify-center text-gray-200  ">
                {
                    feedbackState === true ?
                        <button title="thumbs-up selected" className="w-8 h-8">
                            {thumbsUpSelected()}
                        </button> :
                        <button title="thumbs-up" className="w-8 h-8" onClick={() => handleThumbsPress(true)}>
                            {thumbsUp(pendingFeedback?.satisfied === true)}
                        </button>
                }
                {
                    feedbackState === false ?
                        <button title="thumbs-down selected" className="w-8 h-8">
                            {thumbsDownSelected()}
                        </button> :
                        <button title="thumbs-down" className="w-8 h-8" onClick={() => handleThumbsPress(false)}>
                            {thumbsDown(pendingFeedback?.satisfied === false)}
                        </button>
                }
            </div>
            {feedbackState != undefined &&
                <div className="text-center text-gray-300 ">
                    {"Please add your feedback:"}
                </div>
            }
            {
                feedbackState != undefined &&
                <div
                    data-testid="feedback-box"
                    className={`flex flex-row grow w-full xl:w-1/3 items-center border  ${borderColorClause} rounded-lg ${pendingTextFeedback ? "animate-pulse" : ""}`}>
                    <textarea
                        cols={2}

                        className="bg-inherit grow resize-none w-full border-none focus:border-none border-transparent focus:border-transparent focus:ring-0"
                        value={feedbackText}
                        onChange={(e) => setFeedbackText(e.target.value)}
                        onFocus={(e) => {
                            setFeedbackFocused(true)
                        }}
                        onBlur={(e) => {
                            setFeedbackFocused(false)
                        }}
                    />
                    <Tooltip content="Send text feedback" theme={{"target": "flex flex-col"}}>
                        {/*add 'flex' to className of parent element as a workaround*/}
                        <button title="send-text-feedback" className="w-8 h-8 justify-self-center"
                                onClick={sendTextFeedback}>
                            {sendIcon()}
                        </button>
                    </Tooltip>

                </div>
            }

        </div>
    )
}