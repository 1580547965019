import parse, {attributesToProps, DOMNode, domToReact, Element} from "html-react-parser";
import {useEffect, useState} from "react";
import {useAuth} from "../auth/AuthHook";
import {useErrorReporter} from "./ErrorViewer";
import {resolve_relative} from "./Resolve_relative";


interface DocViewerProps {
    path?: string
    doc_prefix: string
    internal_prefix: string
}

const formattingOptions = (path: string | undefined, doc_prefix: string) => {
    return {
        replace(domNode: DOMNode) {
            return formatElement(domNode, path, doc_prefix);
        }
    }
}


function formatElement(domNode: DOMNode, path: string | undefined, doc_prefix: string) {
    if ((domNode instanceof Element) && domNode.name === 'img' && path != null) {
        const props = attributesToProps(domNode.attribs);
        const {src, alt} = props;

        if (src == null || typeof src != 'string') {
            return domNode;
        }
        if (src.startsWith("http://") || src.startsWith("https://")) {
            return domNode;
        }

        const abs_src = path.startsWith("/") ?
            resolve_relative(`${path}`, src) :
            resolve_relative(`${doc_prefix}/${path}`, src);
        const rest_props = {...props};
        delete rest_props.src;
        delete rest_props.alt;
        return <img className="py-6" src={abs_src} alt={`${alt ?? ""}`} {...rest_props} />;
    }
    if ((domNode instanceof Element) && domNode.name === "h1") {
        return <h1 className="text-4xl py-4 font-bold" {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children as DOMNode[], {})}
        </h1>
    }
    if ((domNode instanceof Element) && domNode.name === "h2") {
        return <h2 className="text-3xl py-4 font-bold" {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children as DOMNode[], {})}
        </h2>
    }
    if ((domNode instanceof Element) && domNode.name === "h3") {
        return <h3 className="text-2xl py-4 " {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children as DOMNode[], {})}
        </h3>
    }
    if ((domNode instanceof Element) && domNode.name === "h4") {
        return <h4 className="text-xl underline py-4 " {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children as DOMNode[], {})}
        </h4>
    }
    if ((domNode instanceof Element) && domNode.name === "ol") {
        return <ol className="list-decimal" {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children as DOMNode[], {})}
        </ol>
    }
    if ((domNode instanceof Element) && domNode.name === "table") {
        const children = domNode.children as DOMNode[];
        return <table className="border border-collapse" {...attributesToProps(domNode.attribs)}>
            {domToReact(children, formattingOptions(path, doc_prefix))}
        </table>
    }
    if ((domNode instanceof Element) && domNode.name === "td") {
        return <td className="border" {...attributesToProps(domNode.attribs)}>
            {domToReact(domNode.children as DOMNode[], {})}
        </td>
    }
    if ((domNode instanceof Element) && domNode.name === "div" && domNode.attribs.class ==="codehilite") {
        const attributes = domNode.attribs;
        delete attributes.class;
        return (
            <div role="code-container" className="flex flex-col w-full overflow-auto">
                <div className="codehilite flex flex-col grow overflow-auto" {...attributesToProps(attributes)}>
                    {domToReact(domNode.children as DOMNode[], {})}
                </div>
            </div>
        )
    }
    return domNode;
}

export function DocViewer({path, doc_prefix, internal_prefix}: DocViewerProps) {
    const {isLoggedIn, setSessionIdCookie} = useAuth();
    const [doc, setDoc] = useState<string | null>(null)
    const reportError = useErrorReporter()
    useEffect(() => {
        const fetchDoc = async () => {
            if (!isLoggedIn()) {
                setDoc("<p>You need to log in to view this document</p>")
                return
            }
            await setSessionIdCookie()
            const response = await fetch(`${internal_prefix}${doc_prefix}/${path}`)
            if (response.status === 404) {
                setDoc("<div class='text-center'></div><h1>Not found</h1>\n\n<p>The referenced document was not found. Sorry about the inconvenience.</p></div>")
                return
            }
            if (!response.ok) {
                reportError(`Error fetching document: ${response.status}, ${await response.text()}`)
                setDoc("<p>Failed to fetch document</p>")
                return
            }
            const text = await response.text()
            setDoc(text)
        }
        if (path != null) {
            fetchDoc()
        }
    }, [path, doc_prefix, internal_prefix, isLoggedIn, reportError, setSessionIdCookie])
    const doc_as_html = parse(doc ?? "# Loading ...", formattingOptions(path, doc_prefix));
    return (
        path == null ? <></> :
            <div id="docviewer-container" className={"flex flex-col p-6 2xl:p-14 items-left w-full overflow-auto"}>
                <div className="flex flex-col items-left leading-loose">
                    {doc_as_html}
                </div>
            </div>
    )
}