import {CaseMetaInfo} from "./model";
import React, {FormEvent, FormEventHandler, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Label} from "flowbite-react";
import {ActionButton} from "./ActionButton";
import {UserNameTag} from "./UserNameTag";
import {formatTimeStamp} from "./utils";
import {useAuth} from "../auth/AuthHook";

interface CaseMetaProps {
    caseId: string | null
    caseMetaInfo: CaseMetaInfo
    onSubmitChanges: (case_id: string | null, changedInfo: CaseMetaInfo) => void
    titleHidden: boolean
    initTimeStamp: number
    issueTrackerName: string
}

export function CaseMeta({caseId, caseMetaInfo, onSubmitChanges, titleHidden, initTimeStamp, issueTrackerName}: CaseMetaProps) {
    const {t} = useTranslation()
    const [meta, setMeta] = useState(caseMetaInfo)
    const titleRef = useRef<HTMLInputElement>(null)
    const [animate, setAnimate] = useState({"num": 0, "value": false})
    const {isLoggedIn} = useAuth()

    const handleSubmission = useCallback((event: FormEvent) => {
        event.preventDefault()
        setTimeout(() => {
            setMeta((m) => {
                onSubmitChanges(caseId, m)
                return m
            })
        }, 0)
    }, [onSubmitChanges, caseId]);
    useEffect(() => {
        if (caseId == null) {
            setAnimate({num: initTimeStamp, value: true})
            titleRef.current?.focus()
            setTimeout(() => {
                setAnimate(({num, value}) => num === initTimeStamp ?
                 ({num: initTimeStamp, value: false}) 
                 : ({num: num, value: value})
                )
            }, 10000)
        }
    }, [caseId, titleRef, initTimeStamp]);

    useEffect(() => {
        setMeta(caseMetaInfo)
    }, [caseMetaInfo]);

    return (
        <div className="p-6">
            <h2 className={`text-center font-bold text-lg pb-4 ${titleHidden ? "hidden" : ""}`}>
                {t("Support case metadata")}
            </h2>
            <form className="flex flex-col gap-4 min-w-48" onSubmit={handleSubmission}>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="meta-input-title" className="text-neutral-500 dark:text-neutral-500"
                               value={t("Enter Support Case title")}/>
                        <span className="text-red-500 text-2xs">*</span>
                    </div>
                    <input id="meta-input-title"
                           className="bg-inherit border border-gray-really focus:border-blue-enov rounded-lg w-full text-white"
                           ref={titleRef}
                           type="text"
                           required
                           value={meta.title}
                           onChange={(e) => {
                               setMeta((m) => ({...m, "title": e.target.value}))
                           }}
                    />
                </div>
                {issueTrackerName !== "notracker" && issueTrackerName !== "" ?
                (<div>
                    <div className="mb-2 block">
                        <Label htmlFor={"meta-input-"+issueTrackerName+"-key"} className="text-neutral-500 dark:text-neutral-500"
                               value={t("Enter the "+issueTrackerName+" project key")}/>
                    </div>
                    <input id={"meta-input-"+issueTrackerName+"-key"}
                           className={`bg-inherit border border-gray-really focus:border-blue-enov rounded-lg w-full ${meta.status === "Escalated" ? "dark:text-neutral-500":"text-white"}`}
                           type="text"
                           value={meta.issue_tracker?.project_key || ''}
                           disabled={meta.status === "Escalated"}
                        onChange={(e) => {
                            setMeta((m) => {
                                const issueTrackerExists = m.issue_tracker !== undefined;
                                return {
                                    ...m,
                                    issue_tracker: issueTrackerExists
                                        ? { ...m.issue_tracker, project_key: e.target.value }
                                        : { project_key: e.target.value }
                                };
                            });
                        }}
                    />
                </div>)
                :<></>}
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="meta-input-severity" className="text-neutral-500 dark:text-neutral-500"
                               value={t("Select the severity of the case")}/>
                        <span className="text-red-500 text-2xs">*</span>
                    </div>
                    <select id="meta-input-severity"
                            className="bg-black border border-gray-really focus:border-blue-enov rounded-lg w-full text-white"
                            required
                            value={meta.severity}
                            onChange={(e) => {
                                setMeta((m) => ({...m, "severity": e.target.value}))
                            }}
                    >
                        <option value="Critical" className={`bg-gray-really`}>{t("Critical (severity)")}</option>
                        <option value="Major" className={`bg-gray-really`}>{t("Major (severity)")}</option>
                        <option value="Normal" className={`bg-gray-really`}>{t("Normal (severity)")}</option>
                        <option value="Minor" className={`bg-gray-really`}>{t("Minor (severity)")}</option>
                    </select>
                </div>
                <div className={caseMetaInfo?.created_by == undefined ? "hidden" : ""}>
                    <UserNameTag
                        userId={caseMetaInfo.created_by}
                        label={t("Case created by")}
                        extraClassNames="text-gray font-bold"
                        extraLabelClassNames="dark:text-neutral-500"
                    />
                </div>
                {
                    caseMetaInfo?.created_by == undefined ? <> </> :
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor={"meta-input-"+issueTrackerName+"-key"} className="text-neutral-500 dark:text-neutral-500"
                                       value={t("Creation time")}/>
                            </div>
                            <div id={"meta-input-"+issueTrackerName+"-key"}
                                   className="bg-inherit w-full text-white"
                            >
                                {meta.creation_time == undefined ? "" : formatTimeStamp(meta.creation_time)}
                            </div>
                        </div>
                }
                <div className={"pt-8 relative inline-flex"}>
                    <ActionButton type="submit"
                                  className={`text-left flex-row justify-start`}>
                        {t(caseId == null ? "Create case" : "Update case meta")}
                    </ActionButton>
                    {animate.value && caseId == null && isLoggedIn()? (
                        <span className="absolute flex right-0 h-3 w-3" data-testid="animate-span">
                              <span
                                  className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-blue-enov-light opacity-75`}></span>
                              <span className="relative inline-flex rounded-full h-3 w-3 bg-blue-enov"></span>
                        </span>
                    ) : <></>
                    }
                </div>
            </form>
        </div>
    )
}
