import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserNameTag} from "./UserNameTag";
import axios from "axios";
import {Tooltip} from "flowbite-react";
import {TailoredMarkdown} from "./TailoredMarkdown";
import {useAuth} from "../auth/AuthHook";

export enum CaseMessageType {
    USER_MESSAGE,
    MESSAGE_RESPONSE
}

export enum CaseMessageRole {
    REQUEST = 0,
    RESPONSE = 1,
    SUMMARY = 2,
    ESCALATION = 3,
    ESCALATION_RESPONSE = 4
}

export type CaseMessage = {
    text: string
    messageType: CaseMessageType
    messageRole: CaseMessageRole
    requestedBy?: string
    timeToRespond?: number
    creationTime?: string
    attachment_id?: string;
}

interface ConversationProps {
    messages: CaseMessage[] | null
    caseId: string | null
    jiraProjectKey?: string
    onVersionChange: (product: string, version: string) => void
}

export function Conversation({messages, caseId, onVersionChange}: ConversationProps) {
    const {t} = useTranslation()
    const {getHeaders} = useAuth()
    const [fileNameMap, setFileNameMap] = useState(new Map<string, string>())

    const handleFileDownload = useCallback(async (attachment_id: string) => {
        const response = await axios.get(`/api/cases/${caseId}/attachments/${attachment_id}/data`, {
            headers: {
                ...await getHeaders(),
                "Content-Type": "application/json"
            },
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = response.headers['content-disposition'].split("filename=")[1];
        a.click();
        window.URL.revokeObjectURL(url);
    }, [caseId, getHeaders])

    useEffect(() => {
        const fetchAttachmentNames = async () => {
            if (messages == null) {
                return
            }
            const attachmentIds = messages.map(m => m.attachment_id).filter(id => id !== undefined) as string[]
            const attachmentNames = await Promise.all(attachmentIds.map(async id => {
                    const resp = await axios.get(`/api/cases/${caseId}/attachments/${id}`, {
                        headers: {
                            ...await getHeaders(),
                            "Content-Type": "application/json"
                        }
                    })
                    return [id, resp.data["file_name"]] as [string, string]
                })
            )
            setFileNameMap(new Map(attachmentNames))
        }
        fetchAttachmentNames()
    }, [messages, caseId, getHeaders]);
    const escalation_idx = 0

    return (
        <div id="conversation-container" className="flex flex-col grow items-center overflow-y-auto">
            {messages == null || messages.length == 0 ?
                <>
                    {
                        caseId == null ?
                            <>
                                <div className={`text-center p-8`}>
                                    {t("No conversation to display")}
                                </div>
                                <div className={`text-center p-8`}>
                                    {t("There are no preceding messages to display")}
                                </div>

                            </> :
                            <div className={`text-center p-8 max-w-[850px]`}>
                                {t("Welcome message")}
                            </div>
                    }
                </> : <></>

            }
            <div className="flex flex-col grow items-center overflow-y-auto">
            <div id="div-to-scroll" className="flex flex-col grow pl-10 pr-14 max-w-[850px]">
                    {messages == null ? [] : messages.map((cm, index) => {
                        const isExportVisible = cm.messageRole === CaseMessageRole.ESCALATION_RESPONSE

                        return (
                            <div key={`message${index}`} className="flex flex-row py-2 overflow-x-auto">
                                <div>
                                    {
                                        cm.messageType === CaseMessageType.USER_MESSAGE ? (
                                                <svg className="w-9 h-9 text-gray-800 dark:text-white" aria-hidden="true"
                                                     xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                                     viewBox="0 0 24 24">
                                                    <path fillRule="evenodd"
                                                          d="M12 20a8 8 0 0 1-5-1.8v-.6c0-1.8 1.5-3.3 3.3-3.3h3.4c1.8 0 3.3 1.5 3.3 3.3v.6a8 8 0 0 1-5 1.8ZM2 12a10 10 0 1 1 10 10A10 10 0 0 1 2 12Zm10-5a3.3 3.3 0 0 0-3.3 3.3c0 1.7 1.5 3.2 3.3 3.2 1.8 0 3.3-1.5 3.3-3.3C15.3 8.6 13.8 7 12 7Z"
                                                          clipRule="evenodd"/>
                                                </svg>
                                            ) :
                                            (
                                                <svg className="w-9 h-9 text-gray-800 dark:text-white"
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     viewBox="0 0 24 24">
                                                    <path fill="currentColor"
                                                          d="M9 15a1 1 0 1 0 1 1a1 1 0 0 0-1-1m-7-1a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1m20 0a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1m-5-7h-4V5.72A2 2 0 0 0 14 4a2 2 0 0 0-4 0a2 2 0 0 0 1 1.72V7H7a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3m-3.28 2l-.5 2h-2.44l-.5-2ZM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h1.22L9 12.24a1 1 0 0 0 1 .76h4a1 1 0 0 0 1-.76L15.78 9H17a1 1 0 0 1 1 1Zm-3-4a1 1 0 1 0 1 1a1 1 0 0 0-1-1"/>
                                                </svg>
                                            )
                                    }
                                </div>
                                <div className="flex flex-col grow overflow-x-auto">
                                    <div className="px-4">
                                        {
                                            cm.messageType === CaseMessageType.USER_MESSAGE ? (
                                                    <UserNameTag
                                                        userId={cm.requestedBy}
                                                        extraClassNames="text-gray font-bold text-sm"
                                                    />) :
                                                (
                                                    <div className="text-blue-enov font-bold text-sm">
                                                        Nestor.ai {cm.messageRole == CaseMessageRole.ESCALATION_RESPONSE ? `(${t("escalation tag")})` : ""}
                                                    </div>
                                                )
                                        }
                                    </div>
                                    <div className="pb-8 flex flex-col overflow-x-auto">
                                        <TailoredMarkdown
                                            className={`flex flex-col grow px-4 text-gray-300 rounded-lg space-y-2 overflow-x-auto leading-relaxed ${cm.messageRole == CaseMessageRole.ESCALATION_RESPONSE ? "bg-gray-dark-enov" : ""}`}
                                            onVersionChange={index == messages.length - 1 ? onVersionChange : null}>
                                            {cm.text}
                                        </TailoredMarkdown>
                                        <div
                                            className={`flex flex-row justify-end space-x-2 w-full px-8 justify-items-center`}>
                                            { cm.attachment_id !== undefined && cm.attachment_id !== null ? (
                                                <button id="download-attachment"
                                                        className={`flex flex-row space-x-1`}
                                                        onClick={(e) => handleFileDownload(cm.attachment_id ?? "")}>
                                                    <div className="text-gray-300 text-xs">
                                                        {fileNameMap.get(cm.attachment_id)}
                                                    </div>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        strokeWidth="1.5"
                                                        stroke="currentColor" className="w-3 h-3">
                                                        <path strokeLinecap="round" strokeLinejoin="round"
                                                            d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13"/>
                                                    </svg>
                                                </button>
                                            ):""}

                                            {cm.timeToRespond ? (
                                                <Tooltip content={t("Time to respond")}
                                                        className={`text-gray-300 text-xs`}>
                                                    <div
                                                        className={`text-gray-300 text-xs ${"pt-[.35rem]" /**could not make it aligned with export button otherwise**/}`}>
                                                        {`${Math.round(cm?.timeToRespond as number)}s`}
                                                    </div>
                                                </Tooltip>
                                            ):""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}