export function resolve_relative(doc_path: string, link_path: string) {
    const parts = link_path.split("/")
    const destPath = doc_path.split("/")
    destPath.pop()
    for (let i = 0; i < parts.length; i++) {
        if (parts[i] === "..") {
            if (destPath.length > 1) {
                destPath.pop()
            } else {
                return `/internal/${link_path}?unresolvable=true`
            }
        } else {
            destPath.push(parts[i])
        }
    }
    return `/internal${destPath.join("/")}`;
}