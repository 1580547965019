import {CaseMessage, Conversation} from "./Conversation";
import {CaseInput} from "./CaseInput";
import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Spinner} from "flowbite-react";
import {formatTimeStamp} from "./utils";
import {SupportCase} from "./model";
import {Feedback} from "./Feedback";

interface DiscussionProps {
    onNewQuestion: (input: string, file_id?: string) => Promise<boolean>
    waitingForResponse: boolean
    supportCase?: SupportCase
}

export function Discussion({onNewQuestion, waitingForResponse, supportCase}: DiscussionProps) {
    const {t} = useTranslation()
    const [productVersions, setProductVersions] = useState<{ [id: string]: string }>({})

    const handleVersionChange = useCallback((product: string, version: string) => {
        setProductVersions((old) => {
            const newVersions = {...old}
            if (version == "" && product in newVersions)
                delete newVersions[product]
            if (version != "")
                newVersions[product] = version
            return newVersions
        })
    }, [setProductVersions])

    const handleNewMessage = useCallback(async (input: string, file_id?: string): Promise<boolean> => {
        if (Object.keys(productVersions).length === 0) {
            return await onNewQuestion(input, file_id)
        }

        const withVersions = `${input}\n\nHere are the product versions we use:\n\`\`\`json\n${(JSON.stringify(productVersions))}\n\`\`\``
        setProductVersions({})
        return await onNewQuestion(withVersions, file_id)
    }, [onNewQuestion, productVersions, setProductVersions])

    return (

        <div id="discussion-frame" className={`flex flex-col pt-4 grow h-full justify-center items-center overflow-y-auto`}>
            {supportCase == undefined || supportCase?.case_id == null ? (
                    <div id="logo-container" className="flex flex-col justify-center items-center space-y-4">
                        <img src="/favicon.png" className="h-12 w-fit self-center"/>
                        <div className="text-xl text-blue-enov text-center font-bold">
                            NESTOR.AI
                        </div>
                        <div className="text-xl text-white text-center">
                            {t("Technical support assistant")}
                        </div>
                    </div>)
                :
                (
                    <div id="conv-n-in" className="flex flex-col grow overflow-y-auto">
                        <div id="discussion-conversation-container" className="flex flex-col overflow-y-auto grow">
                            <Conversation
                            messages={supportCase.messages}
                            caseId={supportCase.case_id}
                            jiraProjectKey={supportCase.issue_tracker?.project_key}
                            onVersionChange={handleVersionChange}/>
                            {waitingForResponse && (
                                <div className={`flex flex-col w-full gap-2 p-6`}>
                                    <Spinner
                                        color="info"
                                        aria-label="Response waiting spinner"
                                        size="lg"
                                        className="animate-spin"/>
                                </div>
                            )}
                            {
                                supportCase.status.toLowerCase() === "closed" && (
                                    <div className={`flex flex-col w-full gap-2 p-6`}>
                                        <div className="text-center text-xl text-red-500">
                                            {supportCase.closure_time === undefined || supportCase.closure_time <= 0 ? "Case is closed" : t("Case is closed at", {closureTime: formatTimeStamp(supportCase.closure_time)})}
                                        </div>
                                        <Feedback caseId={supportCase.case_id} feedback={supportCase.feedback}/>
                                    </div>
                                )
                            }
                        </div>
                        <CaseInput
                            inputHandler={handleNewMessage}
                            disabled={waitingForResponse || supportCase.status.toLowerCase() === "closed"}
                            caseId={supportCase.case_id}/>
                    </div>
                )}
        </div>
    )
}
